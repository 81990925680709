<template>
  <button
    class="w-full flex gap-6 pr-5 pl-5 pt-2 pb-2 items-center text-left text-sm  bg-white hover:bg-gray-100"
    @click="$emit('open')"
  >
    <div class="font-bold flex-shrink-0 overflow-hidden whitespace-nowrap overflow-ellipsis">
      {{ typesList[stage.type]?.[language] ?? $t('notDefined') }}
    </div>

    <div class="w-14 text-gray-500 font-semibold">
      {{ organicUnit?.name || '' }}
    </div>

    <div class="flex-1 text-gray-500 font-semibold">
      {{ teamGroup?.name || '' }}
    </div>

    <div class="w-30">
      <fw-tag v-if="stage.started_at" type="light-primary">
        {{ $t('started') }}
      </fw-tag>
    </div>

    <div class="flex gap-2 text-gray-200 font-semibold text-xs items-center">
      <PrizeIcon class="h-4 w-4" :class="{ 'juc-text-color': !!stage.winner_team }"></PrizeIcon>
      <UnkownTeamIcon v-if="!stage.winner_team" class="h-6 w-6"></UnkownTeamIcon>
      <TeamAvatar v-else :team="stage.winner_team" size="xs"></TeamAvatar>
    </div>
    <fw-icon-chevron-right class="w-5 h-5" />
  </button>
</template>

<script>
import { TOURNAMENT_TYPES } from '@/utils/index.js'
import PrizeIcon from '@/components/icons/PrizeIcon.vue'
import UnkownTeamIcon from '@/components/icons/UnkownTeamIcon.vue'
import TeamAvatar from '@/components/TeamAvatar.vue'

export default {
  components: {
    PrizeIcon,
    UnkownTeamIcon,
    TeamAvatar,
  },
  props: {
    stage: {
      type: Object,
      default: () => {},
    },
    teamGroup: {
      type: Object,
      default: () => {},
    },
    organicUnit: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      showChallonge: false,
      typesList: TOURNAMENT_TYPES,
    }
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "createdAt": "Criado em",
    "notDefined": "Não definido",
    "draft": "Rascunho",
    "published": "Publicado",
    "started": "Torneio iniciado",
    "notStarted": "Torneio ainda não começou",
    "status": {
      "inactive": "Inativo",
      "signup-open": "Inscrições abertas",
      "signup-closed": "Inscrições fechadas",
      "running": "Em curso",
      "finished": "Terminado",
      "canceled": "Cancelado",
      "closed": "Fechado"
    },
    "gender": {
      "U": "Misto",
      "M": "Masculino",
      "F": "Feminino"
    },
    "stages": {
      "final": "Final",
      "intragroups": "Intra-Núcleos",
      "intergroups": "Inter-Núcleos"
    }
  },
  "en": {
    "createdAt": "Created at",
    "notDefined": "Não definido",
    "draft": "Rascunho",
    "published": "Publicado",
    "started": "Torneio iniciado",
    "notStarted": "Torneio ainda não começou",
    "status": {
      "inactive": "Inactive",
      "signup-open": "Signup open",
      "signup-closed": "Signup closed",
      "running": "Running",
      "finished": "Finished",
      "canceled": "Canceled",
      "closed": "Closed"
    },
    "gender": {
      "U": "Mixed",
      "M": "Male",
      "F": "Female"
    },
    "stages": {
      "final": "Final",
      "intragroups": "Intra-Núcleos",
      "intergroups": "Inter-Núcleos"
    }
  }
}
</i18n>
