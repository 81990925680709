<template>
  <fw-layout :back-to="backToPath" mobile-ready :main-sidebar="false" loading-title="UC Competitions">
    <template #main-content>
      <div class="xl:max-w-screen-lg mx-auto px-2 md:px-5 flex flex-col gap-5">
        <BannerTournament
          v-if="!loading && tournament && league && edition && sport"
          :edition="edition"
          :league="league"
          :sport="sport"
          :tournament="tournament"
          :num-teams="sport?.stats?.approved_teams ?? 0"
          :num-players="sport?.stats?.total_players ?? 0"
        />
        <div v-else class="h-80 rounded-3xl bg-gray-200 animate-pulse"></div>

        <fw-panel v-if="tournament && tournament.description[language]" title="Sobre o torneio">
          <div class="content text-sm" v-html="tournament.description[language]" />
        </fw-panel>

        <div
          v-if="!team && tournament && canSignup"
          class="bg-white rounded-xl pt-5 px-5 pb-10 text-primary text-center font-semibold flex flex-col items-center"
        >
          <approvedTeamsIcon class="h-32 w-32" />
          <div class="text-xl mb-4 -mt-3">
            As inscrições estão abertas!
            <div class="text-base">Inscreve já a tua equipa.</div>
          </div>
          <fw-button type="primary" @click.native="signup()">Criar equipa</fw-button>
        </div>

        <fw-panel v-if="team" title="A tua equipa" featured class="my-5">
          <div
            class="flex p-3 w-full gap-6 bg-white rounded-2xl h-16 items-center mt-4 cursor-pointer"
            @click="goToTeam(team)"
          >
            <div class=" w-24 relative h-16">
              <TeamAvatar :team="team" class="absolute -top-4 left-2" />
            </div>
            <div class="flex-1 flex justify-start">
              <div
                class="font-bold text-center border-2 rounded-full px-4 py-0.5"
                :style="{
                  color: team.color ?? '#777777',
                  borderColor: team.color ?? '#777777',
                }"
              >
                <v-clamp v-if="team.name && team.name.length > 0" autoresize :max-lines="1"> {{ team.name }}</v-clamp>
                <span v-else class="overflow-ellipsis overflow-hidden whitespace-nowrap">Sem nome</span>
              </div>
            </div>

            <div class="flex items-center justify-end gap-2">
              <div v-for="(player, p) in firstFourPlayers" :key="'player_' + p">
                <fw-avatar v-if="users[player]" size="xs" :user="users[player]" />
              </div>
              <div v-if="playersExcess > 0" class="text-xs text-gray-500">+{{ playersExcess }}</div>
            </div>

            <div v-if="team.status === 'approved'" class="text-sm text-primary font-semibold flex gap-1 items-center">
              Aprovada
              <fw-icon-shield-check class="h-10 w-10 text-primary" />
            </div>
            <div
              v-else-if="team.status === 'submitted'"
              class="text-sm text-red-600 font-semibold flex gap-1 items-center"
            >
              Aguarda aprovação
              <fw-icon-exclamation-triangle class="h-10 w-10 text-red-500" />
            </div>
            <div
              v-else-if="team.status === 'canceled'"
              class="text-sm text-gray-500 font-semibold flex gap-1 items-center"
            >
              Cancelada
            </div>
            <div>
              <fw-button type="link" size="sm"> Ver equipa </fw-button>
            </div>
          </div>
        </fw-panel>

        <LoadingPlaceholder v-if="loadingStages" />
        <template v-else>
          <fw-panel v-if="!hasOnlyFinalStage && tournamentStages" title="Fases" featured class="my-5">
            <div class="flex flex-col">
              <fw-heading class="mt-5 mb-2" muted>{{ stagesList.intragroups[language] }}</fw-heading>
              <RecordStage
                v-for="(stage, i) in tournamentStages?.intragroups"
                :key="stage.key"
                :stage="stage"
                :team-group="teamGroups[stage.team_group]"
                :organic-unit="
                  teamGroups[stage.team_group]
                    ? organicUnits[teamGroups[stage.team_group].organic_unit]
                    : organicUnits[stage.organic_unit]
                "
                :class="{
                  'border-b border-gray-100': i < tournamentStages?.intragroups.length - 1,
                }"
                @open="gotToStage(stage)"
              ></RecordStage>
            </div>

            <div class="flex flex-col">
              <fw-heading class="mt-5 mb-2" muted>{{ stagesList.intergroups[language] }}</fw-heading>
              <RecordStage
                v-for="(stage, i) in tournamentStages?.intergroups"
                :key="stage.key"
                :stage="stage"
                :team-group="teamGroups[stage.team_group]"
                :organic-unit="
                  teamGroups[stage.team_group]
                    ? organicUnits[teamGroups[stage.team_group].organic_unit]
                    : organicUnits[stage.organic_unit]
                "
                :class="{
                  'border-b border-gray-100': i < tournamentStages?.intergroups.length - 1,
                }"
                @open="gotToStage(stage)"
              ></RecordStage>
            </div>

            <div class="flex flex-col">
              <fw-heading class="mt-5 mb-2" muted>{{ stagesList.final[language] }}</fw-heading>
              <RecordStage
                v-for="(stage, i) in tournamentStages?.final"
                :key="stage.key"
                :stage="stage"
                :team-group="teamGroups[stage.team_group]"
                :organic-unit="
                  teamGroups[stage.team_group]
                    ? organicUnits[teamGroups[stage.team_group].organic_unit]
                    : organicUnits[stage.organic_unit]
                "
                :class="{
                  'border-b border-gray-100': i < tournamentStages?.final.length - 1,
                }"
                @open="gotToStage(stage)"
              ></RecordStage>
            </div>
          </fw-panel>

          <PanelChallongeIframe
            v-else
            :challonge="tournament?.stages?.final?.[0]?.challonge"
            featured
            class="my-5"
          ></PanelChallongeIframe>
        </template>

        <PanelTeams :teams="teams" :loading="loading" :is-tournament="true" />

        <fw-panel-info debug label="Data (raw)">
          <json-viewer :value="{ league, team, edition, sport, tournament }"></json-viewer>
        </fw-panel-info>

        <BannerSportUC />
      </div>
    </template>
  </fw-layout>
</template>

<script>
import PanelChallongeIframe from '@/components/panels/PanelChallongeIframe'
import BannerTournament from '@/components/banners/BannerTournament'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ApprovedTeamsIcon from '@/components/icons/ApprovedTeamsIcon.vue'
import PanelTeams from '@/components/panels/PanelTeams.vue'
import RecordStage from '@/components/records/RecordStage.vue'
import BannerSportUC from '@/components/banners/BannerSportUC.vue'
import TeamAvatar from '@/components/TeamAvatar.vue'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder.vue'
import { TOURNAMENT_STAGES, hasOnlyFinalStage } from '@/utils/index.js'

export default {
  components: {
    BannerTournament,
    PanelChallongeIframe,
    ApprovedTeamsIcon,
    PanelTeams,
    BannerSportUC,
    TeamAvatar,
    RecordStage,
    LoadingPlaceholder,
  },

  data() {
    return {
      loadingStages: true,
      loading: true,
      tournament: null,
      league: null,
      edition: null,
      team: null,
      teams: [],
      users: {},
      sport: null,
      timer: null,
      teamGroups: {},
      stagesList: TOURNAMENT_STAGES,
      tournamentStages: {},
      organicUnits: {},
    }
  },

  computed: {
    firstFourPlayers() {
      return this.team?.players?.slice(0, 4) ?? []
    },

    playersExcess() {
      return this.team?.players?.length - 4 ?? 0
    },

    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language || 'pt'
    },

    tournamentKey() {
      return this.$route.params.tournamentKey
    },

    canSignup() {
      return this.tournament?.validations?.can_signup
    },

    gender() {
      return this.tournament.gender == 'U' ? 'Unisexo' : this.tournament.gender == 'F' ? 'Feminino' : 'Masculino'
    },

    editionKey() {
      return this.$route.params.editionKey
    },

    sportKey() {
      return this.$route.params.sportKey
    },

    view() {
      return this.$route.meta.view
    },

    leagueKey() {
      return this.$route.params.key
    },

    hasOnlyFinalStage() {
      return hasOnlyFinalStage(this.league, this.sport)
    },

    backToPath() {
      return this.$router.resolve({
        name: 'league-view-sports',
        params: {
          editionKey: this.editionKey,
          key: this.leagueKey,
          sportKey: this.sportKey,
        },
      }).href
    },
  },

  mounted() {
    this.getTournament()
    this.getTournamentStages()
  },

  methods: {
    gotToStage(stage) {
      return this.$router.push({
        name: 'league-view-stage',
        params: {
          editionKey: this.editionKey,
          key: this.leagueKey,
          tournamentKey: this.tournamentKey,
          sportKey: this.sportKey,
          stageKey: stage.key,
        },
      })
    },

    async getTournament() {
      this.loading = true
      utils.tryAndCatch(
        this,
        async () => {
          const response = await this.api.getTournament(this.tournamentKey)
          console.log('getTournament :>> ', response)
          this.tournament = response.tournament
          this.teams = response.teams
          this.users = response.users
          this.team = response.team
          await this.getLeagueDetails()
        },
        () => {
          setTimeout(() => {
            this.loading = false
          }, 750)
        }
      )
    },

    async getLeagueDetails() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getLeagueDetails(this.editionKey, this.leagueKey)
        console.log('getLeagueDetails :>> ', response)
        this.edition = response.edition
        this.league = response.league
        this.sport = response.league.sports.find(el => el.key == this.tournament.sport_key)
      })
    },

    async getTournamentStages() {
      utils.tryAndCatch(
        this,
        async () => {
          this.loadingStages = true
          const response = await this.api.getTournamentStages(this.tournamentKey)
          console.log('getTournamentStages :>> ', response)
          this.teamGroups = response.team_groups
          this.organicUnits = response.organic_units
          for (const [key, stages] of Object.entries(response.stages)) {
            this.tournamentStages[key] = stages.sort((a, b) => {
              const aGroup = this.teamGroups?.[a.team_group]
              const bGroup = this.teamGroups?.[b.team_group]
              const aOrgUnit = this.organicUnits?.[a.organic_unit ?? aGroup.organic_unit]
              const bOrgUnit = this.organicUnits?.[b.organic_unit ?? bGroup.organic_unit]
              return `${aOrgUnit?.name}${aGroup?.name}`.localeCompare(`${bOrgUnit?.name}${bGroup?.name}`)
            })
          }
        },
        () => {
          setTimeout(() => {
            this.loadingStages = false
          }, 250)
        }
      )
    },

    goToTeam(team) {
      console.log('team :>> ', team)
      if (team && team.invites?.key) {
        return this.$router.push({
          name: 'team-invite',
          params: { tournamentKey: team.tournament_key, teamKey: team.key, inviteKey: team?.invites?.key },
        })
      }

      if (team && team.stats != 'draft_1') {
        return this.$router.push({
          name: 'team-view',
          params: { tournamentKey: team.tournament_key, teamKey: team.key },
        })
      }

      if (team) {
        return this.$router.push({
          name: 'team-signup-edit',
          params: {
            teamKey: team.key,
            tournamentKey: team.tournament_key,
          },
        })
      }
    },

    signup() {
      this.$router.push({
        name: 'team-signup',
        params: {
          tournamentKey: this.tournamentKey,
          editionKey: this.editionKey,
          key: this.leagueKey,
          sportKey: this.sportKey,
        },
      })
    },
  },
}
</script>

<i18n>
  {
    "pt": {
      "notDefined": "Não definido",
      "createdAt": "Criado em",
      "startsAt": "Início das inscrições",
      "endsAt": "Fim das inscrições",
      "publishedOn": "Publicado em",
      "notFound": "Torneio não encontrado"
    },
    "en": {
      "notDefined": "Not defined",
      "createdAt": "Created at",
      "startsAt": "Signups Start Date",
      "endsAt": "Signups End Date",
      "publishedOn": "Published on",
      "notFound": "Tournament not found"
    }
  }
  </i18n>

<style>
.bg-challonge {
  background-color: #272a33;
}
</style>
